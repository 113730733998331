import React from "react";
import styled from 'styled-components';
import { BiLocationPlus } from "react-icons/bi";

function Location({site}) {
  return (
    <CtaBlock className={'CtaBlock'} background={'white'}>
        <CtaContent className={'CtaContent'}>
        <Row cols={'1'}>
          <IconWrapper className={'IconWrapper'}>
            <Icon className={'Icon'}><BiLocationPlus /></Icon>
            <IconHeader>Our Locations</IconHeader>
            {site.location.split(",").map((text, i) => (
              <IconContent key={`line-${i}`}>{text}</IconContent>
            ))}
          </IconWrapper>
        </Row>
      </CtaContent>
    </CtaBlock>
  );
}

export default Location;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  grid-gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(${props => props.cols === '1' ? '1' : '2'}, minmax(0px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-gap: 32px;
    margin-bottom: 32px;
    grid-template-columns: repeat(${props => props.cols}, minmax(0px, 1fr));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const CtaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 0;
  background: ${props => props.background};
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease 0s;
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 24px;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 60%;
    padding: 32px;
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;

const IconWrapper = styled.div`
  padding: 16px 0;

  @media (min-width: 1024px) {
    padding: 32px 0;
  }
`;

const Icon = styled.div`
  padding: 0;
  text-align: center;
  
  svg {
    font-size: 32px;
    color: #bbbbbb;
  }

  @media (min-width: 1024px) {
    text-align: left;
    padding: 16px 0;

    svg {
      font-size: 42px;
    }
  }
`;

const IconHeader = styled.h4`
  font-size: 22px !important;
  font-weight: bold;
  padding: 0px 0 16px;
  margin: 0 !important;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
    font-size: 24px;
  }
`;

const IconContent = styled.p`
  font-size: 16px !important;
  margin: 0 !important;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }
`;

const Map = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const MapIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;